import { gql } from "@apollo/client";
import { useEffect } from "react";
import { useErrorHandler } from "react-error-boundary";
import { useNavigate } from "react-router-dom";

import {
  useLogoutMutation,
  useRecipeCookMutation,
} from "../../../generated/graphql";

export const LOGOUT_MUTATION = gql`
  mutation Logout {
    logout
  }
`;

export function Logout() {
  let navigate = useNavigate();
  const [recipeCookMutateFunction] = useRecipeCookMutation({});
  const [logoutMutation, { data, loading, error }] = useLogoutMutation({
    onCompleted: () => {
      localStorage.clear();
      navigate("/login?logged-out=true");
      window.location.reload();
    },
  });

  useErrorHandler(error);

  useEffect(() => {
    recipeCookMutateFunction({
      variables: {
        ingredient: {
          recipe_name: "app_launch",
          body: {
            timestamp: new Date().toISOString(),
            object: {},
            verb: "logout",
            context: {},
          },
        },
      },
      onError: (error) => {
        console.log(error.name);
      },
    }).then(() => logoutMutation());
  }, []);

  return <div>Loading</div>;
}
